import { TypeOfT } from "utils/type/translations/Translation.type";
import { validZipCode } from "../validator";

const quoteValidation = (t?: TypeOfT) => ({
    notes: () => ({
        required: '依頼内容を入力してください。',
    }),
    dateTime: () => ({
        required: '引取希望日を入力してください。',
    }),
    zipCode : () =>({
        required: '引き取り郵便番号を入力してください。',
        validate: {
            regex: validZipCode('zipcode', t!),
        }
    }),
    zipCodeReceive : () =>({
        required: '受け渡し郵便番号を入力してください。',
        validate: {
            regex: validZipCode('zipcode', t!),
        }
    }),
    title: () => ({
        required: 'タイトルを入力してください。',
    }),
    content: () => ({
        required: '依頼内容を入力してください。',
    }),
    addressShipping: () => ({
        required: '引き取り住所を入力してください。',
    }),
    address: () => ({
        required: '受け渡し住所を入力してください。',
    }),
    type: () => ({
        required: '種別を選択してください。',
    }),
});

export default quoteValidation;
